<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ $t("sauronAux.addWarning") }}</p>
    </header>
    <section class="modal-card-body">
      <b-field grouped position="is-centered">
        <b-field label-position="on-border" expanded>
          <template slot="label">
            <span class="has-text-primary is-family-sans-serif">Latitude</span>
          </template>
          <b-input v-model="manualWarningObject.lla.latitude"> </b-input>
        </b-field>

        <b-field label-position="on-border" expanded>
          <template slot="label">
            <span class="has-text-primary is-family-sans-serif">Longitude</span>
          </template>
          <b-input v-model="manualWarningObject.lla.longitude"> </b-input>
        </b-field>
      </b-field>

      <b-field grouped v-if="OperationHasCamera" position="is-centered">
        <b-field label-position="on-border" expanded>
          <template slot="label">
            <span class="has-text-primary is-family-sans-serif">Camera</span>
          </template>
          <b-select v-model="manualWarningObject.camera" expanded>
            <option v-for="camera in GetCameras" :key="camera" :value="camera">
              {{ camera }}
            </option>
          </b-select>
        </b-field>
        <b-field label-position="on-border" expanded>
          <template slot="label">
            <span class="has-text-primary is-family-sans-serif">Preset</span>
          </template>
          <b-input v-model="manualWarningObject.preset"> </b-input>
        </b-field>
      </b-field>

      <b-field grouped v-if="OperationHasSoundAlert" position="">
        <b-field label-position="on-border">
          <b-switch
            v-model="soundAlert"
            size="is-large"
            type="is-success"
            passive-type="is-danger"
            outlined
            left-label
          >
            {{ $t("sauronAux.soundAlert") }}
          </b-switch>
        </b-field>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <b-button
        @click="saveModelForm()"
        expanded
        size="is-large"
        type="is-success"
        >{{ $t("sauronAux.save") }}</b-button
      >
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    manualWarningObject: Object,
  },
  name: "ManualWarningGenerator",
  computed: {
    GetCurrentUser() {
      return this.$store.getters["user/GetCurrentUser"];
    },
    GetCameras() {
      return this.$store.getters["database/GetCameras"];
    },
    OperationHasCamera() {
      const operationsWithCamera = ["transpetro", "sabesp"];
      return operationsWithCamera.includes(
        this.GetCurrentUser.activeFlag.toLowerCase()
      );
    },
    OperationHasSoundAlert() {
      const operationsWithSoundAlert = ["sabesp"];
      return operationsWithSoundAlert.includes(
        this.GetCurrentUser.activeFlag.toLowerCase()
      );
    },
  },
  watch: {
    soundAlert: {
      handler(soundAlert) {
        this.manualWarningObject.soundAlert = soundAlert;
      },
    },
  },
  data() {
    return {
      soundAlert: true,
    };
  },
  created() {
    if (this.OperationHasSoundAlert) this.manualWarningObject.soundAlert = this.soundAlert
  },
  methods: {
    saveModelForm() {
      this.$emit("handle-form-data");
    },
  },
};
</script>
